*:has(#spiral-canvas) {
    margin: 0;
    padding: 0;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

#spiral-canvas {
    display: block;
    background: #ffffff;

    &.mirror {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}
