.auth-page {
  .auth-side-wrapper {
    // width: 100%;
    // height: 100%;
    // background-size: cover;

    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: -25.4rem 0px;
    background-size: cover;
  }
}
