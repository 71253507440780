$spin-velocity: 50s;

// .light-bg{
//     opacity: 0;
// }

html,
body {
    margin: 0px;
    padding: 0;
    height: 100vh;
    width: 100vw;
}

#tsparticles {
    cursor: pointer;
    left: -10% !important;
    top: -27vw !important;
    // canvas {
    //     width: 100vw !important;
    //     height: 100vh !important;
    // }
}

#tsparticles.spin {
    canvas {
        // background-color: rgb(255, 255, 255) !important;
        // background-size: cover !important;
        pointer-events: initial !important;
        border-radius: 100vw !important;
    }
}

@for $index from 1 through 100 {
    $value: $index * 1;

    #tsparticles.spin-clockwise-#{$value} {
        canvas {
            -webkit-animation: spinClockwise #{$value}s linear infinite;
            -moz-animation: spinClockwise #{$value}s linear infinite;
            animation: spinClockwise #{$value}s linear infinite;
        }
    }

    #tsparticles.spin-anti-clockwise-#{$value} {
        canvas {
            -webkit-animation: spinAntiClockwise #{$value}s linear infinite;
            -moz-animation: spinAntiClockwise #{$value}s linear infinite;
            animation: spinAntiClockwise #{$value}s linear infinite;
        }
    }
}

@-moz-keyframes spinClockwise {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinClockwise {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinClockwise {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinAntiClockwise {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spinAntiClockwise {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spinAntiClockwise {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
